import React, { useState, useEffect } from 'react';
import ServiceNavigation from './ServiceNavigation.jsx';
import {
    AppLayout, TextContent, BreadcrumbGroup
    , Box, Icon, Container, SpaceBetween, Link,
} from '@amzn/awsui-components-react';
import { Analytics } from 'aws-amplify';
import conceptImage from '../assets/images/demo-repo-concept.svg';
import '../styles/howto.scss';

export default class HowToPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        Analytics.record({ name: 'HowToPageOpen'});
    }

    render() {
        return (
            <AppLayout
                className="awsui-util-no-gutters"
                breadcrumbs={<Breadcrumbs props={this.props} />}
                navigation={<ServiceNavigation />}
                content={<Content />}
                contentType="default"
                navigationOpen={true}
                disableContentPaddings={true}
                toolsHide={true}
            />
        );
    }
}

// Breadcrumb content
const Breadcrumbs = ({ props }) => (
    <BreadcrumbGroup
        items={[
            {
                text: 'Home',
                href: '#/'
            },
            {
                text: 'How To Use',
                href: '#/howto'
            }
        ]}
    />
);

// The content in the main content area of the App layout
function Content(props) {

    return (
        <Box margin='xl'className="howto-box" >
            <Box margin={{ bottom: "xl" }}> <h1 className="awsui-text-large" >How to use</h1></Box>
            <SpaceBetween size="m" >
                <Box>
                    <h1> What is Demo Repository? </h1>
                </Box>
                <Container>

                    <p>&nbsp;</p>
                    <img src={conceptImage} className="card_image" />
                    <p>
                        <Link href='#/'>Demo Repository</Link> literally refers to a repository that stores various forms of demo asset. Demo Repository is a tool for collecting demo ideas based on clear customer needs and working on projects that build demo asset based on each ideas.</p>
                    <p>Sharing already created demo asset to this central repository will also encourage the reuse of various demo assets made by SAs and help AM or TM find demo information easily.</p>

                </Container>
                <Box>
                    <h1> Ideas </h1>
                </Box>
                <Container>
                    <p>As mentioned above, idea page is the place where <strong>you can suggest ideas</strong> if the demo asset customer need doesn&#39;t exist. And if any of the ideas in this page are what you need, just click the <strong>Like!</strong> button.</p>
                    <h4>Getting Started:</h4>
                    <ul>
                        <li>Go to the <Link href='#/ideas'>Ideas Page</Link>.</li>
                        <li>Explore whether your idea is in the list of ideas.</li>
                        <li>If so, tap <strong>Like!</strong> button for that idea.</li>
                        <li>If not, click <strong>Create an Idea</strong> button for suggesting demo asset idea on behalf of customer needs. You can write idea name, description, image that describes the idea well, and etc.</li>

                    </ul>
                </Container>
                <Box>
                    <h1> Projects </h1>
                </Box>
                <Container>
                    <p>In the Demo Repository, <strong>project</strong> means to create a demo asset with a specific topic. For individuals creating demo asset, you can choose one of the ideas presented and build it to demo asset, or if you have already made a demo asset, you can write down what it represents and just upload it. If an individual is looking for a demo asset suitable for the customer&#39;s workload, search the project page for the desired demo asset.</p>
                    <h4>Getting Started concerning the individual who makes the demo asset:</h4>
                    <ul>
                        <li>Go to the <Link href='#/projects'>Project Page</Link>.</li>
                        <li>If there is a demo asset that has already been created, use the <strong>Create a Project</strong> button to write down information related to your demo asset, select the <strong><em>completed</em></strong> for the project progress, and last upload it. You can upload various form of demo asset, such as hands-on, live demo site, workshop, video, and etc.</li>
                        <li>If you are the person who just starts creating a demo asset, find the topic you want to create from the idea page, or if you already have an idea, write it, select the <strong><em>created</em></strong> or <strong><em>ongoing</em></strong> for the project progress, and upload it.</li>

                    </ul>
                    <h4>Getting Started concerning the individual who wants to find suitable demo asset for customer:</h4>
                    <ul>
                        <li>Go to the <Link href='#/projects'>Project Page</Link>.</li>
                        <li>Find the demo asset that you want among the project lists.</li>
                        <li>If you want to know more about the demo asset, you can contact the writer listed in the project and ask him or her about demo&#39;s topic.</li>

                    </ul>
                </Container>
            </SpaceBetween>
        </Box>
    );
}