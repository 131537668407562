import React from 'react';
import { Route } from 'react-router-dom';
import ServiceHomepage from './components/ServiceHomepage.jsx';
import HowToPage from './components/HowTo.jsx';
import ContactUsPage from './components/ContactUs';

import IdeasDashboard from './containers/ideas/IdeaList.jsx';
import { CreateIdea, UpdateIdea } from './components/IdeaCreate.jsx';
import ViewIdea from './components/IdeaPage.jsx';

import ProjectsDashboard from './containers/projects/ProjectList.jsx';
import { CreateProject, UpdateProject } from './components/ProjectCreate.jsx';

import ViewProject from './components/ProjectPage.jsx';
// import  Auth  from '@aws-amplify/auth'; 
import config from './aws-exports.jsx';
import Amplify, { Auth, Hub, Analytics } from 'aws-amplify';

import * as debug from './utils/debug';

config.oauth.redirectSignIn = `${window.location.origin}/`;
config.oauth.redirectSignOut = `${window.location.origin}/`;


Amplify.configure({
  ...config,
  Storage: {
    AWSS3: {
      bucket: 'earth-asset', //REQUIRED -  Amazon S3 bucket name
      region: 'ap-northeast-2', //OPTIONAL -  Amazon service region
    }
  },
  API: {
    endpoints: [
      {
        name: "ideas",
        endpoint: process.env.REACT_APP_IDEAS_ENDPOINT,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      },
      {
        name: "projects",
        endpoint: process.env.REACT_APP_PROJECTS_ENDPOINT,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  },
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    region: 'ap-northeast-2',
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
  },
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: process.env.REACT_APP_AWS_PINPOINT_APP_ID,
    // Amazon service region
    region: 'ap-northeast-2',
    mandatorySignIn: false,
  }
});

const updatePinpointConfiguration = (user) => {
  const { attributes, username } = user;
  const analyticsEndpoint = {
    disabled: false,
    AWSPinpoint: {
      appId: process.env.REACT_APP_AWS_PINPOINT_APP_ID,
      region: 'ap-northeast-2',
      endpointId: `${username}_email`,
      endpoint: {
        address: attributes.email,
        userId: stripUsername(username),
        ChannelType: 'EMAIL',
        optOut: 'NONE',
        userAttributes: {
          amazonId: [ username ],
          amazonIdShort: [ stripUsername(username) ],
        },
      },
    },
  };

  Analytics.configure(analyticsEndpoint);
  console.log(analyticsEndpoint);

  Analytics.autoTrack('pageView', {
    enable: true,
    eventName: 'pageView',
    type: 'SPA',
    provider: 'AWSPinpoint',
  });

  Analytics.autoTrack('session', {
    enable: true,
    provider: 'AWSPinpoint',
  });

};

export function stripUsername(username) {
  return username ? username.slice(7) : username;
}

export const userContext = React.createContext(
  null
);

const getJwtToken = async () => {
  return await (await Auth.currentSession()).getIdToken().getJwtToken();
};

const printWelcome = async (user) => {
  console.log('*---------------------------------------------------*');
  console.log(`Welcome to demogo-prime ${process.env.NODE_ENV}`);
  debug.log(await getJwtToken());
  debug.log(user);
  debug.log(`User : ${JSON.stringify(user.attributes.email.split('@')[0])}`);
  console.log('*---------------------------------------------------*');
}

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: null, customState: null };
  }

  componentDidMount() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          //this.setState({ user: data });
          console.log(`signIn ${data}`);
          break;
        case "signOut":
          //this.setState({ user: null });
          console.log(`signOut ${data}`);
          break;
        case "customOAuthState":
          //this.setState({ customState: data });
          console.log(`customOAuth ${data}`);
      }
    });

    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({ user })
        updatePinpointConfiguration(user);
        printWelcome(user);  
      })
      .catch(() => {
        console.log("Not signed in")
        Auth.federatedSignIn();
      }
    );
  }

  render() {
    return (
      <userContext.Provider value={this.state.user}>
        <div>
          <Route exact path="/" component={ServiceHomepage} />
          <Route exact path="/howto" component={HowToPage} />
          <Route exact path="/contact" component={ContactUsPage} />
          
          <Route path="/ideas/view/:idea_id" component={ViewIdea} />
          <Route exact path="/ideas/create" component={CreateIdea} />
          <Route path="/ideas/update/:idea_id" component={UpdateIdea} />
          <Route exact path="/ideas" component={IdeasDashboard} />

          <Route path="/projects/view/:project_id" component={ViewProject} />
          <Route exact path="/projects/create" component={CreateProject} />
          <Route path="/projects/update/:project_id" component={UpdateProject} />
          <Route exact path="/projects" component={ProjectsDashboard} />
        </div>
      </userContext.Provider>
    );
  }
}

