import * as cardConfig from './cards-config';
import ContentsDashboard from '../../components/ContentsDashboard';


const Projects = () => {
  return <ContentsDashboard 
    name="Project"
    api="projects"
    prefix="project"
    cardConfig={cardConfig}
    cardsPerRow={[{
      cards: 1
    }, {
      minWidth: 1000,
      cards: 2
    }, {
      minWidth: 1400,
      cards: 3
    }]}
  />
}
export default Projects;