import React, { useState, useEffect } from 'react';
import ServiceNavigation from './ServiceNavigation.jsx';
import {
    AppLayout, TextContent, BreadcrumbGroup
    , Box, Icon, Container, SpaceBetween, Link,
} from '@amzn/awsui-components-react';
import { Analytics } from 'aws-amplify';
import '../styles/howto.scss';

const images = [
    { id: 1, src: '/builders/hyperbadge_jungheek.png', name: 'Junghee Kang', role: 'Frontend' },
    { id: 2, src: '/builders/hyperbadge_bjaehyun.png', name: 'JaeHyun Baek', role: 'Frontend' },
    { id: 3, src: '/builders/hyperbadge_joozero.png', name: 'Jooyoung Kim', role: 'Backend' },
    { id: 4, src: '/builders/hyperbadge_junsjang.png', name: 'Junseong Jang', role: 'Backend' },
    { id: 5, src: '/builders/hyperbadge_emmajung.png', name: 'Hyuna Jung', role: 'Database' },

];

export default class ContactUsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        Analytics.record({ name: 'ContactUsPageOpen'});
    }

    render() {
        return (
            <AppLayout
                className="awsui-util-no-gutters"
                breadcrumbs={<Breadcrumbs props={this.props} />}
                navigation={<ServiceNavigation />}
                content={<Content />}
                contentType="default"
                navigationOpen={true}
                disableContentPaddings={true}
                toolsHide={true}
            />
        );
    }
}

// Breadcrumb content
const Breadcrumbs = ({ props }) => (
    <BreadcrumbGroup
        items={[
            {
                text: 'Home',
                href: '#/'
            },
            {
                text: 'Contact Us',
                href: '#/contact'
            }
        ]}
    />
);

// The content in the main content area of the App layout
function Content(props) {

    return (
        <Box margin='xl' className="contactus-box" >
            <Box margin={{ bottom: "xl" }}> <h1 className="awsui-text-large" >Contact Us</h1></Box>
            <SpaceBetween size="m" >
                <Box>
                    <h1>Contact </h1>
                </Box>
                <Container>
                    <TextContent>
                        We love to hear your feedback on how we can improve Demo Repository!
                        <br />
                        For general questions, comments, and feedback related to  Demo Repository, send an email to <Link external externalIconAriaLabel="Opens in a new tab" href="mailto:demo-repository@amazon.com"> demo-repository@amazon.com</Link>
                    </TextContent>
                </Container>
                <Box> <h1>Builders</h1>
                </Box>
                <Container>
                    <SpaceBetween direction="horizontal" size="xxs" >
                        {images.map(({ id, src, name, role }) =>
                            <Box textAlign="center"><img key={id} src={src} title={name} alt={name} />
                                <Box variant="h2">{role}</Box>
                            </Box>
                        )}
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
        </Box>
    );
}