import * as cardConfig from './cards-config';
import ContentsDashboard from '../../components/ContentsDashboard';


const Ideas = () => {
  return <ContentsDashboard 
    name="Idea"
    api="ideas"
    prefix="idea"
    cardConfig={cardConfig}
    cardsPerRow={[{
      cards: 1
    }, {
      minWidth: 1000,
      cards: 2
    }]}
  />
}
export default Ideas;