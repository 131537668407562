import React from 'react';
import { withRouter } from 'react-router-dom';
import { SideNavigation } from '@amzn/awsui-components-react/polaris';

class ServiceNavigation extends React.Component {

  onFollowHandler(ev) {
    if (ev.detail.href) {
      if (!ev.detail.external) {
        ev.preventDefault();
        this.props.history.push(ev.detail.href.substring(1));
      }
    }
  }

  render() {
    return (
      <SideNavigation
        header={{ text: 'Demo Repository', href: '#/' }}
        items={items}
        activeHref={`#${this.props.location.pathname}`}
        onFollow={this.onFollowHandler.bind(this)}
      />
    );
  }
}

const items = [
  { type: 'link', text: 'How To Use', href: '#/howto' },
  { type: "divider" },
  { type: 'link', text: 'Ideas', href: '#/ideas' },
  { type: 'link', text: 'Projects', href: '#/projects' },
  { type: "divider" },
  { type: 'link', text: 'Contact', href: '#/contact' }
  // { type: 'link', text: 'My Dashboard', href: '#/' }

  // {
  //   type: 'section',
  //   text: 'DemoGo Prime',
  //   // expanded: false,
  //   items: [
  //     { type: 'link', text: 'What is DemoGo Prime?', href: 'https://w.amazon.com/bin/view/AWS/Teams/SA/ICN/MMT/2020/#HInitiative1-DemoGoPrime', external: true },
  //     { type: 'link', text: 'Mechanism', href: 'https://w.amazon.com/bin/view/AWS/Teams/SA/APAC/UnicornGym/StarterKit', external: true },
  //     { type: 'link', text: 'Tools', href: 'https://w.amazon.com/bin/view/AWS/Teams/SA/APAC/UnicornGym/StarterKit#HTools', external: true },
  //   ]
  // },
];

export default withRouter(ServiceNavigation);
