import React from 'react';
import { Link, Box, TextContent, Icon, Badge } from '@amzn/awsui-components-react/polaris';


export const CARD_DEFINITIONS = {
  header: item => (
    <div>

      <Link fontSize="heading-m" href={'#/ideas/view/' + item.idea_id} >
        {item.idea_name}
      </Link>
    </div>
  ),
  sections: [
    {
      id: 'idea_id',
      sortingField:'idea_id'
    },{
      id: 'idea_name',
      sortingField:'idea_name'
    },
    {
      id: 'author',
      // header: 'Author',
      content: item => (<Box float="right">by {item.author}</Box>)
    },
    {
      id: 'image',
      header: '',
      content: item => (item.attached_image ? <img src={item.attached_image} className="card_image" /> : <div className="image__placeholder" />)
      ,width: '35'
    },
    {
      id: 'description',
      header: 'Description',
      content: item => (<Box className="box_description" variant="p">
        {item.description&& item.description.length>400?item.description.substring(0,400)+"...":item.description}
      </Box>)
      ,width: '65'
    },
    {
      id: 'services',
      header: 'Contents type',
      content: item =>
      (
        item.services && String(item.services).split(',').length > 0
          ? String(item.services).split(',').map(x =>
            <Badge key={item.idea_name+x.trim()} id={item.idea_name+x.trim()} className='badge_contents_type' color="green">{x.trim()}</Badge>
          )
          : ""
      ),
      width: '30'
    },
    {
      id: 'likes',
      sortingField:'like_count',
      header: 'Likes',
      content: item => (<TextContent><Icon name="heart" size="normal" variant="warning" /> {item.like_count}</TextContent>),
      width: '30'
    },
    {
      id: 'comments',
      sortingField:'reply_count',
      header: 'Comments',
      content: item => (<TextContent><Icon name="contact" size="normal" variant="link" /> {item.reply_count}</TextContent>),
      width: '30'
    }
  ]
};

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Idea properties',
    options: [
      // { id: 'idea_name', label: 'Project name' },
      { id: 'image', label: 'Image' },
      { id: 'author', label: 'Author' },
      { id: 'likes', label: 'Likes' },
      { id: 'comments', label: 'Comments' },
      { id: 'services', label: 'Services' },
    ]
  }
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Ideas' },
  { value: 30, label: '30 Ideas' },
  { value: 50, label: '50 Ideas' }
];

export const CUSTOM_PREFERENCE_OPTIONS = [
  { value: 'cards', label: 'Cards' },
  { value: 'table', label: 'Table' }
];

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['idea_name', 'description', 'author','image',"likes","comments","services"],
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};