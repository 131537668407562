import { API } from 'aws-amplify';
export default class DataProvider {

  getData(api = '',path = '', data = {}) {
    return API.get(api, path, {body:data});
  }

  putData(api = '',path = '', data = {}) {
    return API.put(api, path, {body:data});
  }

  deleteData(api = '',path = '', data = {}) {
    return API.del(api, path, {body:data});
  }

  postData(api = '',path = '', data = {}) {
    return API.post(api, path, {body:data});
  }
}
