import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './App';
import '@amzn/awsui-global-styles/polaris.css';
import reportWebVitals from './reportWebVitals';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.querySelector('#app')
  );
});

reportWebVitals();
