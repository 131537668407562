import React, { useEffect, useState } from 'react';
import DataProvider from '../utils/data-provider';
import ServiceNavigation from './ServiceNavigation.jsx';
import {
  AppLayout,
  Badge,
  BreadcrumbGroup,
  Button,
  SpaceBetween,
  Header,
  Container,
  Table,
  ColumnLayout,
  Box,
  Link,
  ButtonDropdown,
  Spinner
} from '@amzn/awsui-components-react/polaris';
import Replies from './Replies';
import '../styles/contents.scss';
import { Storage, Auth } from 'aws-amplify';
import * as debug from '../utils/debug';

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class ViewIdea extends React.Component {
  constructor(props) {
    super(props);
    this.state = { idea: [], toolsIndex: 0, toolsOpen: false, isLoading: true, like: false, likes: 0, editable: false, deletable: false };
  }

  async componentDidMount() {

    // get contents
    const content = await new DataProvider().getData('ideas', `/${this.props.match.params.idea_id}`, {});
    debug.log(JSON.stringify(content));
    if (content)
      this.setState({
        idea: content,
      });


    // get liked
    const like = await new DataProvider().getData('ideas', `/${this.props.match.params.idea_id}/likes`, {});
    this.setState({ like: like.user_liked, likes: like.count, isLoading: false });

    // delete, edit button enable
    const user = await Auth.currentAuthenticatedUser()
    const identities = JSON.parse(user.attributes.identities);
    const userId = identities[0].userId;

    if (userId === content.author || user.signInUserSession.accessToken.payload["cognito:groups"].includes('admin')) {
      this.setState({
        editable: true,
        deletable: true
      })
    }

    this.setState({ isLoading: false });




  }

  onclick_delete(id) {
    let dataProvider = new DataProvider();
    if (id)
      dataProvider.deleteData('ideas', `/${id}`, {})
        .then(data => {
          debug.log(`Idea id : ${id} was deleted!`);
          debug.log(JSON.stringify(data));
          window.location.href = '/#/ideas';
        }
        ) // JSON-string from `response.json()` call
        .catch(error => console.error(error));
  }

  onclick_like(id) {

    console.log(this.state.like);
    const dataProvider = new DataProvider();
    if (this.state.like) {
      dataProvider.deleteData('ideas', `/${id}/likes`, {})
        .then(data => {
          this.setState({ like: !this.state.like, likes: data.count });
          debug.log(`I don't like ${id} idea`);
        })
        .catch(err => console.error(err));
    } else {
      dataProvider.postData('ideas', `/${id}/likes`, {})
        .then(data => {
          this.setState({ like: !this.state.like, likes: data.count });
          debug.log(`I like ${id} idea`);

        })
        .catch(err => console.error(err));
    }
  }

  render() {
    return (
      <AppLayout
        navigation={<ServiceNavigation activeHref="#/ideas" />} // Navigation panel content imported from './ServiceNavigation.jsx'
        breadcrumbs={<Breadcrumbs props={this.props} />}
        content={
          <SpaceBetween size="m">
            <PageHeader idea={this.state.idea} buttons={[
              { text: this.state.like ? 'Unlike!' : 'Like!', variant: 'primary', onClick: () => this.onclick_like(this.state.idea.idea_id) },
              { text: 'Delete', disabled: this.state.isLoading || !this.state.deletable, onClick: () => { if (window.confirm('Are you sure?')) { this.onclick_delete(this.state.idea.idea_id); } } },
              { text: 'Edit', disabled: this.state.isLoading || !this.state.editable, href: `#/ideas/update/${this.state.idea.idea_id}` },
            ]} />
            <DetailContent
              replyUrl={`/${this.props.match.params.idea_id}/replies`}
              idea={this.state.idea}
              likes={this.state.likes}
              isLoading= {this.state.isLoading}
            />
          </SpaceBetween>
        }
        contentType="default"
        maxContentWidth="1400px"
      // tools={Tools[this.state.toolsIndex]}
      // toolsOpen={this.state.toolsOpen}
      />
    );
  }
}

const PageHeader = ({ idea, buttons }) => {
  return (
    <Header
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          {buttons.map((button, key) =>
            !button.items ? (
              <Button href={button.href || ''} disabled={button.disabled || false} variant={button.variant || 'normal'} onClick={button.onClick || ''} key={key}>
                {button.text}
              </Button>
            ) : (
              <ButtonDropdown items={button.items} key={key}>
                {button.text}
              </ButtonDropdown>
            )
          )}
        </SpaceBetween>
      }
    >
      Idea : {idea.idea_name}
    </Header>
  );
};

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

function DetailContent({ replyUrl, idea, likes, isLoading }) {

  const [S3Image, setS3Image] = useState(null);

  useEffect(() => {
    if (idea && idea.attached_image) {
      Storage.get(idea.attached_image.split("/")[4], { expires: 3600, level: 'public' }).then(result => {
        setS3Image(result);
      }
      ) // JSON-string from `response.json()` call
        .catch(error => console.error(error));
    }
  }, [idea]);

  return (
    <SpaceBetween size="m">
      <Container
        header={
          <Header variant="h2">Basic information</Header>
        }
      >
        {(isLoading)?<Spinner size="large" variant="normal" />:
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Attached Image"> {idea.attached_image ? idea.attached_image : "No Image"}</ValueWithLabel>
            {idea.attached_image && idea.attached_image.startsWith('S3://')
              ? (<img src={S3Image} className="card_image" />)
              : idea.attached_image && !idea.attached_image.startsWith('S3://')
                ? (<img src={idea.attached_image} className="card_image" />) : (<div className="custom-home-image__placeholder" />)}

          </SpaceBetween>
          <SpaceBetween size="l">
            <Box variant="h3" padding="n">History</Box>
            <ValueWithLabel label="Created date">{idea.created_date}</ValueWithLabel>
            <ValueWithLabel label="Last updated date">{idea.updated_date}</ValueWithLabel>
            <ValueWithLabel label="Writer">
              <Box >
                <Link href={'https://phonetool.amazon.com/users/' + String(idea.author).replace("@", "")}>
                  <img src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + String(idea.author).replace("@", "")} alt="avatar" />
                </Link>
              </Box>
              <Link href={'https://phonetool.amazon.com/users/' + idea.author}>
                {idea.author}
              </Link>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <Box variant="h3" padding="n">
              Metrics
        </Box>
            {/* <ValueWithLabel label="View"> <Badge color="red">TBD</Badge>  </ValueWithLabel> */}
            <ValueWithLabel label="Liked"> <Badge color="red">{likes}</Badge>  </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <Box variant="h3" padding="n">
              Related Services
        </Box>
            <ValueWithLabel label="">
              {
                idea.services && String(idea.services).split(',').length > 0
                  ? String(idea.services).split(',').map(x =>
                    <Badge id={idea.idea_name + x.trim()} key={idea.idea_name + x.trim()} className='badge_contents_type' color="green">{x.trim()}</Badge>
                  )
                  : ""}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
}
      </Container>
      <Container
        header={
          <Header variant="h2">Description</Header>
        }
      >
        <Box className="box_detail_description" variant="p">
          {idea.description}
        </Box>
      </Container>
      <Table
        variant="borderless"
        columnDefinitions={[
          {
            id: 'variable',
            header: 'Content Type',
            cell: item => item.name
          },
          {
            id: 'value',
            header: 'Location',
            cell: item =>
              <Link href={item.value} target="_blank" rel="noopener noreferrer" external={true}>
                {item.value}
              </Link>
          }
        ]}
        items={getResources({ idea })}
        header={
          <Header variant="h2">Resources</Header>}
      />
      {/* </Container> */}
      <Replies
        contentType={'ideas'}
        replyUrl={replyUrl}
      ></Replies>
    </SpaceBetween>
  );
}

function getResources({ idea }) {
  let items = [];
  if (idea && idea.link) {
    if (typeof idea.link === 'string' || idea.link instanceof String) {
      //check if a string can be parsed as JSON
      if (/^[\],:{}\s]*$/.test(idea.link.replace(/\\["\\\/bfnrtu]/g, '@').
        replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
        replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
        JSON.parse(idea.link).forEach(element => {
          items.push({
            name: element.key,
            value: element.value
          });
        });
      }
      else {
        items.push({
          name: 'Link',
          value: idea.link
        });
      }
    }
  }

  if (idea && idea.attached_file)
    items.push({
      name: 'File',
      value: idea.attached_file
    });
  return items
}

// Breadcrumb content
const Breadcrumbs = ({ props }) => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Home',
        href: '#/'
      },
      {
        text: 'Ideas',
        href: '#/ideas'
      },
      {
        text: 'View an Idea',
        href: '#/ideas/' + props.match.params.idea_id
      }
    ]}
  />
);

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
  <div>
  </div>
];
