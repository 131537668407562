import React, { useEffect, useState } from 'react';
import DataProvider from '../utils/data-provider';
import ServiceNavigation from './ServiceNavigation.jsx';
import Replies from './Replies';
import {
  AppLayout,
  Badge,
  BreadcrumbGroup,
  Button,
  SpaceBetween,
  Header,
  Container,
  Table,
  ColumnLayout,
  Box,
  Link,
  ButtonDropdown,
  StatusIndicator,Spinner
} from '@amzn/awsui-components-react/polaris';
import '../styles/contents.scss';
import { Storage, Auth } from 'aws-amplify';
import * as debug from '../utils/debug';

export default class ViewProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = { project: [], toolsIndex: 0, toolsOpen: false, isLoading: true, like: false, likes: 0, deletable: false, editable: false };
  }

  async componentDidMount() {

    const content = await new DataProvider().getData('projects', `/${this.props.match.params.project_id}`, {});
    debug.log(JSON.stringify(content));
    if (content && content.length == 1)
      this.setState({
        project: content[0],
      });


    // get liked
    const like = await new DataProvider().getData('projects', `/${this.props.match.params.project_id}/likes`, {});
    this.setState({ like: like.user_liked, likes: like.count, isLoading: false });

    // delete, edit button enable

    const user = await Auth.currentAuthenticatedUser()
    const identities = JSON.parse(user.attributes.identities);
    const userId = identities[0].userId;

    if (userId === content[0].author || (content[0].contributors && content[0].contributors.indexOf(userId) > -1) || user.signInUserSession.accessToken.payload["cognito:groups"].includes('admin')) {
      this.setState({
        editable: true,
        deletable: true
      })
    }

    this.setState({ isLoading: false });
  }

  onclick_delete(id) {
    let dataProvider = new DataProvider();
    if (id)
      dataProvider.deleteData('projects', `/${id}`, {})
        .then(data => {
          debug.log(`Project id : ${id} was deleted!`);
          debug.log(JSON.stringify(data));
          window.location.href = '/#/projects';
        }
        ) // JSON-string from `response.json()` call
        .catch(error => console.error(error));
  }

  onclick_like(id) {

    debug.log(this.state.like);
    const dataProvider = new DataProvider();
    if (this.state.like) {
      dataProvider.deleteData('projects', `/${id}/likes`, {})
        .then(data => {
          this.setState({ like: !this.state.like, likes: data.count });
          debug.log(`I don't like ${id} project`);
        })
        .catch(err => console.error(err));
    } else {
      dataProvider.postData('projects', `/${id}/likes`, {})
        .then(data => {
          this.setState({ like: !this.state.like, likes: data.count });
          debug.log(`I like ${id} project`);
        })
        .catch(err => console.error(err));
    }
  }

  render() {
    return (
      <AppLayout
        navigation={<ServiceNavigation activeHref="#/projects" />} // Navigation panel content imported from './ServiceNavigation.jsx'
        breadcrumbs={<Breadcrumbs props={this.props} />}
        content={
          <SpaceBetween size="m">
            <PageHeader project={this.state.project} buttons={[
              { text: this.state.like ? 'Unlike!' : 'Like!', variant: 'primary', onClick: () => this.onclick_like(this.state.project.project_id) },
              { text: 'Delete', disabled: this.state.isLoading || !this.state.deletable, onClick: () => { if (window.confirm('Are you sure?')) { this.onclick_delete(this.state.project.project_id) } } },
              { text: 'Edit', disabled: this.state.isLoading || !this.state.editable, href: `#/projects/update/${this.state.project.project_id}` }]} />
            <DetailContent
              replyUrl={`/${this.props.match.params.project_id}/replies`}
              project={this.state.project}
              likes={this.state.likes}
              isLoading= {this.state.isLoading}
            />
          </SpaceBetween>
        }
        contentType="default"
        maxContentWidth="1400px"
      // tools={Tools[this.state.toolsIndex]}
      // toolsOpen={this.state.toolsOpen}
      />
    );
  }
}

const PageHeader = ({ project, buttons }) => {
  return (
    <Header
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          {buttons.map((button, key) =>
            !button.items ? (
              <Button href={button.href || ''} disabled={button.disabled || false} variant={button.variant || 'normal'} onClick={button.onClick || ''} key={key}>
                {button.text}
              </Button>
            ) : (
              <ButtonDropdown items={button.items} key={key}>
                {button.text}
              </ButtonDropdown>
            )
          )}
        </SpaceBetween>
      }
    >
      Project : {project.project_name}
    </Header>
  );
};

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

function DetailContent({ replyUrl, project, likes, isLoading }) {

  const [S3Image, setS3Image] = useState(null);

  useEffect(() => {
    if (project && project.attached_image) {
      Storage.get(project.attached_image.split("/")[4], { expires: 3600, level: 'public' }).then(result => {
        setS3Image(result);
      }
      ) // JSON-string from `response.json()` call
        .catch(error => console.error(error));
    }
  }, [project]);

  return (
    <SpaceBetween size="m" >
      
      <Container 
        header={
          <Header variant="h2">Basic information </Header>
        }
      >
        {(isLoading)?<Spinner size="large" variant="normal" />:
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Attached Image"> {project.attached_image ? project.attached_image : "No Image"}</ValueWithLabel>
            {project.attached_image && project.attached_image.startsWith('S3://')
              ? (<img src={S3Image} className="card_image" />)
              : project.attached_image && !project.attached_image.startsWith('S3://')
                ? (<img src={project.attached_image} className="card_image" />) : (<div className="custom-home-image__placeholder" />)}
          </SpaceBetween>
          <SpaceBetween size="l">
            <Box variant="h3" padding="n">History</Box>
            <ValueWithLabel label="Created date">{project.created_date}</ValueWithLabel>
            <ValueWithLabel label="Last updated date">{project.updated_date}</ValueWithLabel>
            <ValueWithLabel label="Project Status"><StatusIndicator type={project.status === 'completed' ? 'success' : project.status === 'ongoing' ? 'in-progress' : 'info'}>{project.status}</StatusIndicator></ValueWithLabel>
            <ValueWithLabel label="Writer">
              <Box >
                <Link href={'https://phonetool.amazon.com/users/' + String(project.author).replace("@", "")}>
                  {(project && project.author) ? <img src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + String(project.author).replace("@", "")} alt="avatar" /> : "" }
                </Link>
              </Box>
              <Link href={'https://phonetool.amazon.com/users/' + project.author}>
                {project.author}
              </Link>
            </ValueWithLabel>
            <ValueWithLabel label="Contributors">
              <SpaceBetween direction="horizontal" size="s">
                {
                  project.contributors && String(project.contributors).split(',').length > 0
                    ? String(project.contributors).split(',').map(x =>
                      <SpaceBetween key={x} direction="vertical" size="n">
                        <img src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + x.trim().replace("@", "")} alt="avatar" />
                        <Box>
                          <Link href={'https://phonetool.amazon.com/users/' + x.trim()}> {x.trim()}
                          </Link>
                        </Box>
                      </SpaceBetween>
                    )
                    : ""
                }
              </SpaceBetween>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <Box variant="h3" padding="n">
              Metrics
            </Box>
            <ValueWithLabel label="SFDC Campaign Code (Optional)"> {project.sfdc} </ValueWithLabel>
            <ValueWithLabel label="Liked"> <Badge color="red">{likes}</Badge>  </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <Box variant="h3" padding="n">
              {/* Related Services */}
            </Box>
            <ValueWithLabel label="Related Services">
              {
                project.services && String(project.services).split(',').length > 0
                  ? String(project.services).split(',').map(x =>
                    <Badge id={project.project_name + x.trim()} key={project.project_name + x.trim()} className='badge_contents_type' color="green">{x.trim()}</Badge>
                  )
                  : ""}
            </ValueWithLabel>
            <ValueWithLabel label="Contents Type">{
              project.contents_type && String(project.contents_type).split(',').length > 0
                ? String(project.contents_type).split(',').map(x =>
                  <Badge id={project.project_name + x.trim()} key={project.project_name + x.trim()} className='badge_contents_type' color="blue">{x.trim()}</Badge>
                )
                : ""
            }</ValueWithLabel>
            <ValueWithLabel label="Complexity">{project.complexity}</ValueWithLabel>
            <ValueWithLabel label="Share">{project.share}</ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      }
      </Container>
      <Container
        header={
          <Header variant="h2">Description</Header>
        }
      >
        <Box className="box_detail_description" variant="p">
          {project.description}
        </Box>
      </Container>
      <Table
        variant="borderless"
        columnDefinitions={[
          {
            id: 'variable',
            header: 'Content Type',
            cell: item => item.name
          },
          {
            id: 'value',
            header: 'Location',
            cell: item =>
              <Link href={item.value} target="_blank" rel="noopener noreferrer" external={true}>
                {item.value}
              </Link>
          }
        ]}
        items={getResources({ project })}
        header={
          <Header variant="h2">Resources</Header>}
      />
      {/* </Container> */}
      <Replies
        contentType={'projects'}
        replyUrl={replyUrl}
      ></Replies>
    </SpaceBetween>
  );
}

function getResources({ project }) {
  let items = [];
  if (project && project.link) {
    if (typeof project.link === 'string' || project.link instanceof String) {
      //check if a string can be parsed as JSON
      if (/^[\],:{}\s]*$/.test(project.link.replace(/\\["\\\/bfnrtu]/g, '@').
        replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
        replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
        JSON.parse(project.link).forEach(element => {
          items.push({
            name: element.key,
            value: element.value
          });
        });
      }
      else {
        items.push({
          name: 'Link',
          value: project.link
        });
      }
    }
  }

  if (project && project.attached_file)
    items.push({
      name: 'File',
      value: project.attached_file
    });
  return items
}

// Breadcrumb content
const Breadcrumbs = ({ props }) => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Home',
        href: '#/'
      },
      {
        text: 'Projects',
        href: '#/projects'
      },
      {
        text: 'View a project',
        href: '#/projects/' + props.match.params.project_id
      }
    ]}
  />
);

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
  <div>
  </div>
];
