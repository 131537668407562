import React, { useEffect, useState } from "react";
import moment from 'moment'
import { Auth } from 'aws-amplify';
import * as debug from '../utils/debug';

import {
  Badge,
  BreadcrumbGroup,
  Button,
  SpaceBetween,
  Header,
  Container,
  FormField,
  Input,
  Grid,
  Link,
  Icon,
  Box
} from '@amzn/awsui-components-react/polaris';
import DataProvider from "../utils/data-provider";

const Replies = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [replies, setReplies] = useState([]);
  //const user = useContext(userContext);
  const [author, setAuthor] = useState("");


  useEffect(() => {

    Auth.currentAuthenticatedUser()
      .then(user => {
        setAuthor(user.attributes.email.split("@")[0]);
      })
      .catch(err => console.log(err));

    new DataProvider().getData(`${props.contentType}`, `${props.replyUrl}`, {})
      .then(data => {
        debug.log(data);
        if (data === `'NoneType' object has no attribute 'items'`)
          data = [];
        setReplies(data);
      })
      .catch(e => console.log("error" + e))
  }, [])

  const send = () => {
    const url = props.replyUrl;
    if (inputValue === "")
      return;

    const payload = {
      'author': author,
      'reply': inputValue
    }
    new DataProvider().postData(`${props.contentType}`, `${url}`, payload)
      .then(res => {
        const newReplies = [...replies, res];
        setReplies(newReplies);
        setInputValue("");
      })
      .catch(e => {
        console.log('error' + e);
      })
  }

  const deleteReply = (reply_id) => {
    const url = props.replyUrl;
    new DataProvider().deleteData(`${props.contentType}`, `${url}/${reply_id}`)
      .then(data => {
        debug.log(data);

        setReplies(replies.filter(replies => replies.reply_id !== reply_id));

      })
      .catch(err => {
        console.log(err);
      })
  }

  const onKeyUp = ({ detail }) => {
    if (detail.keyCode === 13)
      send();
  }


  const convertLocalTime = (time) => {
    const offset = new Date().getTimezoneOffset();
    const utc = moment.utc(time).utcOffset(-offset);

    // moment.utc(time).utcOffset(-new Date().getTimezoneOffset()).format('MM-DD HH:mm')

    return utc.format('MM-DD HH:mm');
  }

  return (
    <Container
      header={
        <Header
          variant="h2"
        >
          Feedbacks
        </Header>
      }
    >
      <Grid
        gridDefinition={[{ colspan: 12 }]}>
        <div>
          <FormField
            secondaryControl={<Button onClick={send}>Send</Button>}
          >
            <Input
              value={inputValue}
              onChange={event =>
                setInputValue(event.detail.value)
              }
              onKeyUp={onKeyUp}
            />
          </FormField>
        </div>
      </Grid>
      {replies.map((reply) => {
        return (
          <Grid
            id={reply.reply_id}
            key={reply.reply_id}
            gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}
          >
            <Grid
              disableGutters
              gridDefinition={[{ colspan: 12 },
              { colspan: 12 },
              { colspan: 12 }]}>
              <Box  float="left">
                <SpaceBetween direction="horizontal" size="xs">
                <Box>
                  <img src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + String(reply.author).replace("@", "")} className="avatar" alt="avatar" />
                </Box>

                <Box>
                  <Link href={'https://phonetool.amazon.com/users/' + reply.author}>
                    {reply.author}
                  </Link>
                  <Box>{convertLocalTime(reply.last_updated)}</Box> 
                </Box>

                </SpaceBetween>
                
                </Box>

            </Grid>
            
            <Box padding="l">{reply.reply}                 <Box float="right" margin={{ right: "xxl" }}>{author == reply.author ? (
                <Button href="." onClick={(e) => {
                  e.preventDefault();
                  if (window.confirm('Are you sure?')) {
                    deleteReply(reply.reply_id);
                  }
                }}>del</Button>
              ) : null}</Box></Box>

          </Grid>
        );
      })}
    </Container>
  );
}

export default Replies;