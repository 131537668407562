import React from 'react';
import { Link, StatusIndicator, Badge, TextContent, Icon } from '@amzn/awsui-components-react/polaris';


export const CARD_DEFINITIONS = {
  header: item => (
    <Link fontSize="heading-m" href={'#/projects/view/' + item.project_id} >
      {item.project_name}
    </Link>
  ),
  sections: [
    {
      id: 'project_id',
      sortingField:'project_id'
    },
    {
      id: 'project_name',
      sortingField:'project_name'
    },
    {
      id: 'image',
      header: '',
      content: item =>
      (
        item.attached_image
          ? (<img src={item.attached_image} className="card_image" />)
          : (<div className="custom-home-image__placeholder" />)
        // item.attached_image ? <img src={item.attached_image} className="card_image"/> : <div className="custom-home-image__placeholder" />
      )
    },
    {
      id: 'author',
      header: 'Contributors',
      content: item => item.author + (item.contributors ? ", " + item.contributors : '')
    },
    {
      id: 'share',
      header: 'Share',
      content: item => (<StatusIndicator type={item.share === 'public' ? 'success' : item.share === 'internal' ? 'stopped' : 'error'}>{item.share}</StatusIndicator>),
      width: '50'
    },
    {
      id: 'status',
      header: 'Status',
      content: item => (<StatusIndicator type={item.status === 'completed' ? 'success' : item.status === 'ongoing' ? 'in-progress' : 'info'}>{item.status}</StatusIndicator>),
      width: '50'
    },
    {
      id: 'likes',
      sortingField:'like_cnt',
      header: 'Likes',
      content: item => (<TextContent><Icon name="heart" size="normal" variant="warning" /> {item.like_cnt}</TextContent>),
      width: '50'
    },
    {
      id: 'comments',
      sortingField:'reply_cnt',
      header: 'Comments',
      content: item => (<TextContent><Icon name="contact" size="normal" variant="link" /> {item.reply_cnt}</TextContent>),
      width: '50'
    },
    {
      id: 'contents_type',
      header: 'Contents type',
      content: item =>
      (
        item.contents_type && String(item.contents_type).split(',').length > 0
          ? String(item.contents_type).split(',').map(x =>
            <Badge key={item.project_name+x.trim()} id={item.project_name+x.trim()} className='badge_contents_type' color="blue">{x.trim()}</Badge>
          )
          : ""
      ),
      width: '50'
    },
    {
      id: 'services',
      header: 'Contents type',
      content: item =>
      (
        item.services && String(item.services).split(',').length > 0
          ? String(item.services).split(',').map(x =>
            <Badge key={item.project_name+x.trim()} id={item.project_name+x.trim()} className='badge_contents_type' color="green">{x.trim()}</Badge>
          )
          : ""
      ),
      width: '50'
    }
  ]
};

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Project properties',
    options: [
      { id: 'project_name', label: 'Project name' },
      { id: 'contributors', label: 'Contributors' },
      { id: 'likes', label: 'Likes' },
      { id: 'comments', label: 'Comments' },
      { id: 'share', label: 'Share' },
      { id: 'contents_type', label: 'Contents type' },
      { id: 'services', label: 'Services' },
      { id: 'status', label: 'Status' }
    ]
  }
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Projects' },
  { value: 30, label: '30 Projects' },
  { value: 50, label: '50 Projects' }
];

export const CUSTOM_PREFERENCE_OPTIONS = [
  { value: 'cards', label: 'Cards' },
  { value: 'table', label: 'Table' }
];

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['project_name', 'image','likes', 'comments', 'contents_type',  'contributors', 'share', 'status'],
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};