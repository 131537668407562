import React, { useState, useEffect } from 'react';
import DataProvider from '../utils/data-provider';
import ServiceNavigation from './ServiceNavigation.jsx';
import { useCollection } from '@amzn/awsui-collection-hooks';

import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  SpaceBetween,
  FormField,
  RadioGroup,
  Cards, Box,
  Pagination,
  TextFilter,
  CollectionPreferences,
  SegmentedControl,
  Header
} from '@amzn/awsui-components-react/polaris';

import '../styles/contents.scss';
import { Auth, Storage } from 'aws-amplify';
import * as debug from '../utils/debug';

const ContentsDashboard = ({name, api, prefix, cardConfig, cardsPerRow}) => {
    return (
      <AppLayout
        navigation={<ServiceNavigation />}
        breadcrumbs={<Breadcrumbs name={name} api={api}/>}
        content={<DetailsCards name={name} api={api} prefix={prefix} cardConfig={cardConfig} cardsPerRow={cardsPerRow}/>}
        contentType="cards"
        toolsOpen={false}
        tools={Tools}
        maxContentWidth="1600px"
      />
    );
}

export default ContentsDashboard;

export const TableNoMatchState = props => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <Box>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </Box>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const TableEmptyState = ({ resourceName }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No {resourceName.toLowerCase()}s</b>
        <Box variant="p" color="inherit">
          No {resourceName.toLowerCase()}s associated with this resource.
        </Box>
      </div>
      <Button>Create {resourceName.toLowerCase()}</Button>
    </SpaceBetween>
  </Box>
);
export const getFilterCounterText = count => `${count} ${count === 1 ? 'match' : 'matches'}`;

function getCounter(props) {
  if (props.counter) {
    return props.counter;
  }
  if (!props.totalItems) {
    return null;
  }
  if (props.serverSide) {
    return getServerHeaderCounterText(props.totalItems, props.selectedItems);
  }
  return getHeaderCounterText(props.totalItems, props.selectedItems);
}

export const getHeaderCounterText = (items = [], selectedItems = []) => {
  return selectedItems && selectedItems.length > 0 ? `(${selectedItems.length}/${items.length})` : `(${items.length})`;
};

export const getServerHeaderCounterText = (totalCount, selectedItems) => {
  return selectedItems && selectedItems.length > 0 ? `(${selectedItems.length}/${totalCount}+)` : `(${totalCount}+)`;
};

export const TableHeader = props => {
  return (
    <Header
      counter={getCounter(props)}
      // info={props.updateTools && <InfoLink onFollow={props.updateTools} />}
      description={props.description}
      actions={props.actionButtons}
    >
      {props.title}
    </Header>
  );
};

function onclick_delete(api, id, setLoading, setOnDeleting, setSelectedDatas) {
  let dataProvider = new DataProvider();
  if (id)
    dataProvider.deleteData(api, `/${id}`, {})
      .then(data => {
        debug.log(`id : ${id} was deleted!`);
        debug.log(JSON.stringify(data));
        setLoading(true);
        setOnDeleting(true);
        setSelectedDatas([]);
      }
      ) // JSON-string from `response.json()` call
      .catch(error => console.error(error));
}
export const ListHeader = ({ ...props }) => {
  const isOnlyOneSelected = props.selectedItems.length === 1;

  return (
    <TableHeader
      {...props}
      title={props.name}
      actionButtons={
        <SpaceBetween size="xs" direction="horizontal">
          <Button onClick={() => window.location.href = `#/${props.api}/view/${Reflect.get(props.selectedItems[0], `${props.prefix}_id`)}`} disabled={props.selectedItems.length === 0} >View details</Button>
          <Button onClick={() => window.location.href = `#/${props.api}/update/${Reflect.get(props.selectedItems[0], `${props.prefix}_id`)}`} disabled={!props.editable}>Edit</Button>
          <Button onClick={() => onclick_delete(props.api, Reflect.get(props.selectedItems[0], `${props.prefix}_id`), props.setLoading, props.setOnDeleting, props.setSelectedDatas)} disabled={!props.deletable}>Delete</Button>
          <Button variant="primary" href= {`#/${props.api}/create`} >Create a {`${props.name}`}</Button>
        </SpaceBetween>
      }
    />
  );
};

function onchange_select(user, items, setEditable, setDeletable, setSelectedDatas) {
  const identities = JSON.parse(user.attributes.identities);
  const userId = identities[0].userId;
  // if (userId === items[0].author || (items[0].contributors && items[0].contributors.indexOf(userId) > -1)||user.signInUserSession.accessToken.payload["cognito:groups"].includes('admin')) {
  if (userId === items[0].author || (items[0].contributors && items[0].contributors.indexOf(userId) > -1)) {
    setEditable(true);
    setDeletable(true);
  }
  else {
    setEditable(false);
    setDeletable(false);
  }
  setSelectedDatas(items);
}

async function getS3Image(data, setProjects, setLoading) {

  // RIGHT :: Array.map using async-await and Promise.all
  const parsed_images = await Promise.all(
    data.map(project => {
      if (project.attached_image && project.attached_image.startsWith('S3://')) {
        return new Promise((resolve, reject) => {
          Storage.get(project.attached_image.split("/")[4], { expires: 3600, level: 'public' }).then(string => {
            resolve({ ...project, attached_image: string })
          });
        });
      }
      else return { ...project, attached_image: project.attached_image }
    })
  );
  console.log(parsed_images);
  setProjects(parsed_images);
  setLoading(false);
  return parsed_images;
}

function DetailsCards({ updateTools, name, api, prefix, cardConfig, cardsPerRow }) {
  const [loading, setLoading] = useState(true);
  const [onDeleting, setOnDeleting] = useState(false);
  const [datas, setDatas] = useState([]);
  const [selectedDatas, setSelectedDatas] = useState([]);
  const [preferences, setPreferences] = useState(cardConfig.DEFAULT_PREFERENCES);
  const [selectedId, setSelectedId] = React.useState(
    "latest"
  );
  const [user, setUser] = useState();
  const [editable, setEditable] = useState(false);
  const [deletable, setDeletable] = useState(false);


  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    datas,
    {
      filtering: {
        empty: <TableEmptyState resourceName={name} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      sorting: {
        defaultState: {
          isDescending: true, sortingColumn:
            { sortingField: `${prefix}_id` }
        }
      },
      pagination: { pageSize: preferences.pageSize }
    }
  );
  useEffect(() => {
    // user= await Auth.currentAuthenticatedUser() ;
    Auth.currentAuthenticatedUser()
      .then(user => setUser(user))
      .catch(err => console.log(err));

    new DataProvider().getData(api, '', {})
      .then(data => {
        getS3Image(data, setDatas, setLoading).then(result => {
          // console.log(JSON.stringify(result))
        });
      }
      ) // JSON-string from `response.json()` call
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    if (onDeleting) {
      new DataProvider().getData(api, '', {})
        .then(data => {
          getS3Image(data, setDatas, setLoading).then(result => {
            setOnDeleting(false);
            // console.log(JSON.stringify(result))
          });
        }
        ) // JSON-string from `response.json()` call
        .catch(error => console.error(error));
    }
  }, [onDeleting]);

  return (
    <Cards
      {...collectionProps}
      // stickyHeaderVerticalOffset={getStickyTopOffset(this.props.notifications)}
      // stickyHeader={true}
      trackBy={`${prefix}_id`}
      cardDefinition={cardConfig.CARD_DEFINITIONS}
      visibleSections={preferences.visibleContent}
      loading={loading}
      loadingText="Loading..."
      items={items}
      selectionType="single"
      onSelectionChange={event => onchange_select(user, event.detail.selectedItems, setEditable, setDeletable, setSelectedDatas)}
      selectedItems={selectedDatas}
      // ariaLabels={distributionSelectionLabels}
      cardsPerRow={cardsPerRow}
      header={
        <ListHeader
          name={name}
          api={api}
          prefix={prefix}
          selectedItems={selectedDatas}
          totalItems={datas}
          updateTools={updateTools}
          setLoading={setLoading}
          setOnDeleting={setOnDeleting}
          setSelectedDatas={setSelectedDatas}
          editable={editable}
          deletable={deletable}
        />
      }
      filter={
        <SpaceBetween size="l" className="input-container" direction="horizontal">
          <SegmentedControl
            selectedId={selectedId}
            onChange={({ detail }) => {
              setSelectedId(detail.selectedId)

              if (detail.selectedId == "latest") {
                actions.setSorting({
                  isDescending: true,
                  sortingColumn:
                    { sortingField: `${prefix}_id` }
                });
              }
              else if (detail.selectedId == "oldest") {
                actions.setSorting({
                  isDescending: false,
                  sortingColumn:
                    { sortingField: `${prefix}_id` }
                });
              }
              else if (detail.selectedId == "popular") {
                actions.setSorting({
                  isDescending: true,
                  sortingColumn:
                    { sortingField: "like_cnt" }
                });
              }
              else {
                actions.setSorting({
                  isDescending: false,
                  sortingColumn:
                    { sortingField: `${prefix}_name` }
                });

              }
              // console.log(collectionProps)
            }
            }
            label="Segmented control with a disabled segment"
            options={[
              {
                text: "Oldest",
                id: "oldest"
              },
              { text: "Latest", id: "latest" },
              {
                text: "Most Liked", id: "popular"
              },
            ]}
          />
          <TextFilter
            {...filterProps}
            filteringAriaLabel={`Filter ${name}`}
            filteringPlaceholder={`Find ${name}`}
            countText={getFilterCounterText(filteredItemsCount)}
            className="input-filter"
            disabled={loading}
          />
        </SpaceBetween>
      }
      pagination={<Pagination {...paginationProps}
        // ariaLabels={paginationLabels} 
        disabled={loading} />}
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          disabled={loading}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: 'Page size',
            options: cardConfig.PAGE_SIZE_OPTIONS
          }}
          visibleContentPreference={{
            title: 'Select visible columns',
            options: cardConfig.VISIBLE_CONTENT_OPTIONS
          }}
          customPreference={(value, setValue) => (
            <FormField stretch={true} label="View as">
              <RadioGroup
                value={value}
                onChange={({ detail }) => setValue(detail.value)}
                items={cardConfig.CUSTOM_PREFERENCE_OPTIONS}
              />
            </FormField>
          )}
        />
      }
    />
  );
}

// Breadcrumb content
const Breadcrumbs = ({name, api}) => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Home',
        href: '#/'
      },
      {
        text: name,
        href: `#/${api}`
      }
    ]}
  />
);

// Help (right) panel content
const Tools = [
  <div key="help-panel" className="awsui-util-help-panel">
  </div>
];
