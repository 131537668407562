import React, { useState, useEffect, useContext } from 'react';
import DataProvider from '../utils/data-provider';
import ServiceNavigation from './ServiceNavigation.jsx';
import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Form,
  FormField,
  Input,
  Textarea,
  Header, Autosuggest, SpaceBetween, Container, Box, TokenGroup,
  TagEditor
} from '@amzn/awsui-components-react/polaris';
import { Auth, Storage } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import service_list from '../assets/raw/service_list.json';
import * as debug from '../utils/debug';

export class CreateIdea extends React.Component {
  constructor(props) {
    super(props);
    this.state = { toolsIndex: 0, toolsOpen: false, idea: { idea_name: '' } };
  }
  componentDidMount() {
  }

  render() {
    return (
      <AppLayout
        contentType="form"
        navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
        breadcrumbs={<BreadcrumbsCreate />}
        content={
          <FormHeader
            // Changes the Help panel content when the user clicks an 'info' link
            // replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
            idea={this.state.idea}
            title="Create an Idea"
          />
        }
        tools={Tools[this.state.toolsIndex]}
        toolsOpen={this.state.toolsOpen}
      />
    );
  }
}

export class UpdateIdea extends React.Component {
  constructor(props) {
    super(props);
    this.state = { toolsIndex: 0, toolsOpen: false, idea: [] };
  }
  componentDidMount() {

    new DataProvider().getData('ideas', `/${this.props.match.params.idea_id}`, {})
      .then(data => {
        debug.log(JSON.stringify(data));
        if (data) this.setState({ idea: data })
      }
      ) // JSON-string from `response.json()` call
      .catch(error => console.error(error));
  }

  render() {
    return (
      <AppLayout
        contentType="form"
        navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
        breadcrumbs={<BreadcrumbsUpdate />}
        content={
          <FormHeader
            // Changes the Help panel content when the user clicks an 'info' link
            // replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
            title="Update an Idea"
            idea={this.state.idea}
          />
        }
        tools={Tools[this.state.toolsIndex]}
        toolsOpen={this.state.toolsOpen}
      />
    );
  }
}
// The content in the main content area of the App layout
function FormHeader({ title, idea }) {

  const [idea_name, setIdea_name] = useState('');
  const [attached_image, setAttached_image] = useState("");
  const [uploaded_image, setUploaded_image] = useState(null);
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');
  const [services, setServices] = useState('');
  const [link, setLink] = useState([]);
  const [isvalidating, setIsvalidating] = useState(false);
  // const user = useContext(userContext);
  const [user, setUser] = useState();
  const [value, setValue] = React.useState("");

  useEffect(() => setIdea_name(idea.idea_name), [idea.idea_name]);
  useEffect(() => setAttached_image(idea.attached_image), [idea.attached_image]);
  useEffect(() => setAuthor(idea.author), [idea.author]);
  useEffect(() => setDescription(idea.description), [idea.description]);
  // useEffect(() => setServices(idea.services), [idea.services]);
  useEffect(() => {

    if (typeof idea.services === 'string' || idea.services instanceof String) {
      //check if a string can be parsed as JSON
      if (/^[\],:{}\s]*$/.test(idea.services.replace(/\\["\\\/bfnrtu]/g, '@').
        replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
        replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
      }
      else {
        let temp_services = [];
        idea.services.split(',').forEach(x => { temp_services.push({ label: x }) })
        idea.services = temp_services;
        setServices(idea.services)
      }
    }
  }, [idea.services]);

  useEffect(() => {
    if (idea.link) {
      if (typeof idea.link === 'string' || idea.link instanceof String) {
        if (/^[\],:{}\s]*$/.test(idea.link.replace(/\\["\\\/bfnrtu]/g, '@').
          replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
          replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) { idea.link = JSON.parse(idea.link); }
        else {
          idea.link = [{ key: 'ETC', value: idea.link }];
        }
      }
      setLink(idea.link)
    }
  }, [idea.link]);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        if (!idea.author)
          setAuthor(user.attributes.email.split("@")[0]);
        setUser(user);
      })
      .catch(err => console.log(err));


  }, []);


  function onclick() {
    setIsvalidating(true);
    if (!idea_name || idea_name === "") {
      return;
    }
    if (!author || author === "") {
      return;
    }
    if (!description || description === "") {
      return;
    }

    let parse_services = Array.isArray(services) ? (services.map(x => x.label)).toString() : services;
    let parse_link = link;

    let temp = { idea_name, attached_image, author, description, services: parse_services, link }
    let dataProvider = new DataProvider();
    debug.log(temp);
    // return ;
    if (idea.idea_id) {
      dataProvider.putData('ideas', `/${idea.idea_id}`, temp)
        .then(data => {
          debug.log(JSON.stringify(data));
          window.location.href = '#/ideas';
        }
        ) // JSON-string from `response.json()` call
        .catch(error => console.error(error));
    }
    else {
      dataProvider.postData('ideas', '/', temp)
        .then(data => {
          debug.log(JSON.stringify(data));
          window.location.href = '#/ideas';
        }
        ) // JSON-string from `response.json()` call
        .catch(error => console.error(error));
    }
  }

  return (
    <div>
      <Form
        header={
          <Header
            variant="h1"
          // info={<InfoLink id="form-main-info-link" onFollow={() => updateTools(0)} />}
          // description="When you create an Amazon CloudFront distribution, you tell CloudFront where to find your content by specifying your origin servers."
          >
            {title}
          </Header>
        }
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" href="#/ideas">
              Cancel
        </Button>
            <Button variant="primary" onClick={onclick} >{title}</Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          <Container
            header={<Header variant="h2">Basic information</Header>}
          >
            <SpaceBetween direction="vertical" size="xl">
              <FormField
                label={
                  <span id="delivery-method-label">
                    Idea Name
              <Box variant="span" margin={{ left: 'xs' }}>
                      {/* <InfoLink id="delivery-method-info-link" onFollow={() => this.props.updateTools(1)} /> */}
                    </Box>
                  </span>
                }
                stretch={true}
                errorText={isvalidating && !idea_name ? "Idea name is required." : ""}
              >
                <Input
                  value={idea_name}
                  placeholder="Enter Idea Name"
                  onChange={event =>
                    setIdea_name(event.detail.value)
                  }
                  invalid={isvalidating && !idea_name}
                />
              </FormField>
              <FormField
                label={
                  <span id="delivery-method-label">
                    Description
              <Box variant="span" margin={{ left: 'xs' }}>
                      {/* <InfoLink id="delivery-method-info-link" onFollow={() => this.props.updateTools(1)} /> */}
                    </Box>
                  </span>
                }
                stretch={true}
                errorText={isvalidating && !description ? "Description is required." : ""}
              >
                <Textarea
                  onChange={({ detail }) => setDescription(detail.value)}
                  value={description}
                  rows={10}
                  placeholder="Please explain about this demo idea."
                />
              </FormField>
              <FormField
                label={
                  <span id="delivery-method-label">
                    Image (URL)
                    <Box variant="span" margin={{ left: 'xs' }}>
                      {/* <InfoLink id="delivery-method-info-link" onFollow={() => this.props.updateTools(1)} /> */}
                    </Box>
                  </span>
                }
                stretch={true}
              >
                <Input
                  value={attached_image}
                  placeholder="Please attach an image that describes the idea well."
                  onChange={event =>
                    setAttached_image(event.detail.value)
                  }
                  disabled={uploaded_image}
                /> or <S3ImageUpload setUploaded_image={setUploaded_image} setAttached_image={setAttached_image}></S3ImageUpload>
              </FormField>
              <FormField
                label={
                  <span id="delivery-method-label">
                    Related AWS Services
                    <Box variant="span" margin={{ left: 'xs' }}>
                      {/* <InfoLink id="delivery-method-info-link" onFollow={() => this.props.updateTools(1)} /> */}
                    </Box>
                  </span>
                }
                stretch={false}
                secondaryControl={
                  <Button onClick=
                    {() => {
                      var isExist = (services && services.filter(i => i.label === value).length > 0) ? true : false;
                      debug.log(services);
                      debug.log(value);
                      if ((value.length > 0) && !(isExist))
                        setServices([...services, { "label": value }]);
                      setValue("");
                    }
                    } variant="normal"> Add </Button>
                }
              >
                <Autosuggest
                  onChange={({ detail }) => setValue(detail.value)}
                  value={value}
                  placeholder="Enter related AWS services "
                  options={service_list}
                  enteredTextLabel={value => `Use: "${value}"`}
                  ariaLabel="Autosuggest example with suggestions"
                  placeholder="Enter value"
                  empty="No matches found"
                />
                <TokenGroup
                  onDismiss={({ detail: { itemIndex } }) => {
                    setServices([
                      ...services.slice(0, itemIndex),
                      ...services.slice(itemIndex + 1)
                    ]);
                  }}
                  items={services}
                />
                {/* <Input
                  value={services}
                  placeholder="Enter related AWS services separated by commas."
                  onChange={event =>
                    setServices(event.detail.value)
                  }
                /> */}
              </FormField>
              <FormField
                label={
                  <span id="delivery-method-label">
                    Writer (email alias without @)
                    <Box variant="span" margin={{ left: 'xs' }}>
                      {/* <InfoLink id="delivery-method-info-link" onFollow={() => this.props.updateTools(1)} /> */}
                    </Box>
                  </span>
                }
                stretch={true}
                errorText={isvalidating && !author ? "Writer is required." : ""}
              >
                <Input
                  value={author}
                  placeholder="Enter an email alias."
                  onChange={event =>
                    setAuthor(event.detail.value)
                  }
                  invalid={isvalidating && !author}
                  disableBrowserAutocorrect
                  autoComplete={false}
                />
              </FormField>
            </SpaceBetween>
          </Container>
          <Container
            header={<Header variant="h2">Related link</Header>}
          >
            <FormField
              label={
                <span id="delivery-method-label">
                  {/* Idea assets */} Reference link for this idea
              <Box variant="span" margin={{ left: 'xs' }}>
                    {/* <InfoLink id="delivery-method-info-link" onFollow={() => this.props.updateTools(1)} /> */}
                  </Box>
                </span>
              }
              stretch={true}
            >
              <TagEditor
                allowedCharacterPattern=""
                i18nStrings={{
                  keyPlaceholder: "Enter resource type",
                  valuePlaceholder: "Enter resource location",
                  addButton: "Add new link",
                  removeButton: "Remove",
                  undoButton: "Undo",
                  undoPrompt:
                    "This tag will be removed upon saving changes",
                  loading:
                    "Loading tags that are associated with this resource",
                  keyHeader: "Resource type",
                  valueHeader: "Resource location",
                  keySuggestion: "Custom tag key",
                  valueSuggestion: "Custom tag value",
                  emptyTags:
                    "No links associated with the resource.",
                  tooManyKeysSuggestion:
                    "You have more keys than can be displayed",
                  tooManyValuesSuggestion:
                    "You have more values than can be displayed",
                  keysSuggestionLoading: "Loading tag keys",
                  keysSuggestionError:
                    "Tag keys could not be retrieved",
                  valuesSuggestionLoading: "Loading tag values",
                  valuesSuggestionError:
                    "Tag values could not be retrieved",
                  emptyKeyError: "You must specify a tag key",
                  maxKeyCharLengthError:
                    "The maximum number of characters you can use in a tag key is 128.",
                  maxValueCharLengthError:
                    "The maximum number of characters you can use in a tag value is 256.",

                  tagLimit: availableTags =>
                    availableTags === 1
                      ? "You can add 1 more link."
                      : "You can add " +
                      availableTags +
                      " more links.",
                  tagLimitReached: tagLimit =>
                    tagLimit === 1
                      ? "You have reached the limit of 1 links."
                      : "You have reached the limit of " +
                      tagLimit +
                      " links.",
                  tagLimitExceeded: tagLimit =>
                    tagLimit === 1
                      ? "You have exceeded the limit of 1 links."
                      : "You have exceeded the limit of " +
                      tagLimit +
                      " links.",
                  enteredKeyLabel: key => 'Use "' + key + '"',
                  enteredValueLabel: value => 'Use "' + value + '"'
                }}
                tags={link}
                onChange={({ detail }) => setLink(detail.tags)}
                keysRequest={() =>
                  Promise.resolve([
                    "Wiki",
                    "Quip",
                    "Link",
                    "Source (Github)",
                    "ETC"
                  ])
                }
                valuesRequest={(key, value) =>
                  key
                    ? Promise.resolve(["http://", "https://"])
                    : Promise.reject()
                }
              // tagLimit={10}
              />
            </FormField>
          </Container>
        </SpaceBetween>
      </Form>
    </div>
  );
}

class S3ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { uploading: false }
    this.uploadFile.bind(this)

  }

  uploadFile = async (file) => {
    const fileName = uuid();
    const result = await Storage.put(
      fileName + "-" + file.name,
      file,
      {
        level: 'public'
      }
    );

    this.props.setUploaded_image(`S3://${Storage._config.AWSS3.bucket}/public/${fileName + "-" + file.name}`)
    this.props.setAttached_image(`S3://${Storage._config.AWSS3.bucket}/public/${fileName + "-" + file.name}`)
    console.log('Uploaded file: ', result);

  }

  onChange = async (e) => {
    this.setState({ uploading: true });

    let files = [];
    for (var i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files.item(i));
    }
    await Promise.all(files.map(f => this.uploadFile(f)));

    this.setState({ uploading: false });
  }

  render() {
    return (
      <div>

        <Button
          onClick={() => document.getElementById('add-image-file-input').click()}
          disabled={this.state.uploading}
          icon='file image outline'
          content={this.state.uploading ? 'Uploading...' : 'Add Images'}
        >{this.state.uploading ? 'Uploading...' : 'Choose local file'}</Button>

        <input
          id='add-image-file-input'
          type="file"
          accept='image/*'
          onChange={this.onChange}
          style={{ display: 'none' }}
        />
      </div>
    );
  }
}

// Breadcrumb content
const BreadcrumbsCreate = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Home',
        href: '#/'
      },
      {
        text: 'Ideas',
        href: '#/ideas'
      },
      {
        text: 'Create an Idea',
        href: '#/ideas/create'
      }
    ]}
  />
);
const BreadcrumbsUpdate = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Home',
        href: '#/'
      },
      {
        text: 'Ideas',
        href: '#/ideas'
      },
      {
        text: 'Update an Idea',
        href: '#/ideas/update'
      }
    ]}
  />
);

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
  <div ></div>
];
