import React, { useState, useEffect } from 'react';
import ServiceNavigation from './ServiceNavigation.jsx';
import DataProvider from '../utils/data-provider';
import {
  CARD_DEFINITIONS,
  VISIBLE_CONTENT_OPTIONS,
  CUSTOM_PREFERENCE_OPTIONS,
  PAGE_SIZE_OPTIONS,
  DEFAULT_PREFERENCES
} from './../containers/projects/cards-config';
import {
  AppLayout, Button, ColumnLayout, FormField, Cards, Input
  , Box, Grid, Container, SpaceBetween, Link, Header, TextFilter
} from '@amzn/awsui-components-react';
import '../styles/servicehomepage.scss';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Storage } from 'aws-amplify';
import * as debug from '../utils/debug';

export default class ServiceHomepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      pageSize: 30,
      filteringText: '',
    };
  }

  setFilteringText(filteringText) {
    this.setState({ filteringText: filteringText });
  }
  componentDidMount() {

    // new DataProvider().getData('projects', '', {})
    //   .then(data => {
    //     console.log(data)
    //     this.setState({ projects: data });
    //     // setProjects(data);
    //     // setLoading(false);
    //   }
    //   ) // JSON-string from `response.json()` call
    //   .catch(error => console.error(error));
  }

  render() {
    return (
      <AppLayout
        className="awsui-util-no-gutters"
        navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
        content={<Content projects={this.state.projects} filteringText={this.state.filteringText} setFilteringText={this.setFilteringText.bind(this)} />}
        contentType="default"
        navigationOpen={true}
        disableContentPaddings={true}
        toolsHide={true}
      />
    );
  }
}

var cardsPerRow = [
  {
    "cards": 1
  },
  {
    "minWidth": 500,
    "cards": 2
  }
];

async function getS3Image(data, setProjects, setLoading) {

  // RIGHT :: Array.map using async-await and Promise.all
  const parsed_images = await Promise.all(
    data.map(project => {
      if (project.attached_image && project.attached_image.startsWith('S3://')) {
        return new Promise((resolve, reject) => {
          Storage.get(project.attached_image.split("/")[4], { expires: 3600, level: 'public' }).then(string => {
            resolve({ ...project, attached_image: string })
          });
        });
      }
      else return { ...project, attached_image: project.attached_image }
    })
  );
  debug.log(parsed_images);
  setProjects(parsed_images);
  setLoading(false);
  return parsed_images;
}

// The content in the main content area of the App layout
function Content(props) {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [filteringText, setfilteringText] = useState('');

  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    projects,
    {
      filtering: {
        // empty: <TableEmptyState resourceName="Project" />,
        // noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      }, sorting: {
        defaultState: {
          isDescending: true, sortingColumn:
            { sortingField: "like_cnt" }
        }
      },
      pagination: { pageSize: 5 }
    }
  );
  useEffect(() => {
    new DataProvider().getData('projects', '', {})
      .then(data => {
        getS3Image(data, setProjects, setLoading).then(result => {
          // console.log(JSON.stringify(result))
        });
      }
      ) // JSON-string from `response.json()` call
      .catch(error => console.error(error));
  }, []);

  return (
    <SpaceBetween  >
      <Grid gridDefinition={[{ colspan: { xxs: '12' } }, { colspan: { xxs: '12' } }]} className="custom-home__header">
        <Box padding={{ vertical: 'xxl' }}>
          <Grid
            gridDefinition={[
              { offset: { l: '2', xxs: '1' }, colspan: { l: '8', xxs: '10' } },
              { colspan: { xl: '6', l: '5', s: '6', xxs: '10' }, offset: { l: '2', xxs: '1' } },
              { colspan: { xl: '2', l: '3', s: '4', xxs: '10' }, offset: { s: '0', xxs: '1' } }
            ]}
          >
            <Box fontWeight="light" padding={{ top: 'xs' }}>
              <span className="custom-home__category"></span>
            </Box>
            <div className="custom-home__header-title">
              <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
                Demo Repository
              </Box>
              <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="heading-xl" color="inherit">
                Most customer-centric demo repository and demo building mechanism
              </Box>
              <Box variant="p" fontWeight="light">
                <span className="custom-home__header-sub-title">
                  'Demo Repository' is a new tool of SA Korea team, produces repeatable demos that prioritize the needs of its customers, and help SA acquire appropriate resources needed for content creation and accelerate it.
                </span>
              </Box>
            </div>

            <Container>
              <SpaceBetween size="xl">
                <Box variant="h2" padding="n">
                  Submit your demo or demo idea
                </Box>
                <FormField stretch={true} label="">
                  <Button href="#/ideas/create" variant="primary">Demo idea</Button>
                  <span> or </span>
                  <Button href="#/projects/create" variant="normal">Demo project</Button>
                </FormField>
              </SpaceBetween>
            </Container>
          </Grid>
        </Box>
        {/* <Box padding={{ bottom: 'xxl' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: '5', l: '5', s: '6', xxs: '10' }, offset: { l: '2', xxs: '1' } },
            { colspan: { xl: '2', l: '1', s: '2', xxs: '10' }, offset: { xxs: '1', "default": 0, s: '0', l: '0' } }
          ]}
        >
          <Box>
            <FormField stretch={true}>
              <Input placeholder="Search" type="search" value={filteringText} onChange={(event)=>setfilteringText(event.value)}></Input>
            </FormField>
          </Box>
          <Box margin={{ left: 'n' }}>
            <FormField stretch={true}>
              <Button variant='primary'>Search</Button> 
            </FormField>
          </Box>
        </Grid>
      </Box> */}
      </Grid>
      <Box className="card_projects" margin={{ top: 'm' }} padding={{ top: 'xxl', horizontal: 'm' }}>

        <Cards
          {...collectionProps}
          cardDefinition={CARD_DEFINITIONS}
          visibleSections={preferences.visibleContent}
          items={items}
          cardsPerRow={cardsPerRow}
          loadingText="Loading Projects"
          header={
            <Header variant="h1">
              Popular demos
        </Header>
          }
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel="Filter projects"
              filteringPlaceholder="Find projects"
              // countText={getFilterCounterText(filteredItemsCount)}
              disabled={loading}
            />
          }
          empty={<div className="awsui-util-t-c">
            <div className="awsui-util-pt-xs awsui-util-mb-xs">
              <b>
                No resources
              </b>
            </div>
            <p className="awsui-util-mb-s">
              No resources to display
            </p>
          </div>}
        >
        </Cards>
      </Box>
    </SpaceBetween>

  );
}